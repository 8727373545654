<template lang="html">
  <section class="section shop">
    <div class="section__header text-center">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <h1
              class="section__title h3"
              v-t="'account.reset-password.title'"
            ></h1>
            <p
              class="section__intro text-sm"
              v-t="'account.reset-password.description'"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <div class="section__body">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <div
              class="box__content bg-light p-3 mb-3"
              v-if="form.errors.length"
            >
              <strong v-t="'account.reset-password.error'"
                >Something went wrong:</strong
              >
              <ul class="mt-2">
                <li v-for="(error, i) in form.errors" :key="i">{{ error }}</li>
              </ul>
            </div>

            <form @submit.prevent="submit" @submit="sendNewPassword($event)">
              <div class="form-group mb-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <label
                    for="input-email-1"
                    class="label--big"
                    v-t="'account.login.email-label'"
                    >Email address</label
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.email.$error }"
                  id="input-email"
                  name="email"
                  :placeholder="$t('account.login.email-placeholder')"
                  aria-describedby="email-1"
                  v-model="form.email"
                  readonly="readonly"
                />
              </div>

              <div class="form-group mb-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <label
                    for="new_password"
                    class="label--big"
                    v-t="'account.reset-password.new-password-label'"
                    >New password</label
                  >
                </div>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.new_password.$error }"
                  id="input-new_password"
                  name="new_password"
                  :placeholder="
                    $t('account.reset-password.new-password-placeholder')
                  "
                  aria-describedby="new_password"
                  v-model="form.new_password"
                />
              </div>
              <div class="form-group mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <router-link
                    :to="{ name: 'Login' }"
                    class="btn btn-sm btn-empty"
                  >
                    <i class="icon icon-keyboard_arrow_left"></i>
                    <span v-t="'account.reset-password.back-to-sign-in'">
                      Back to sign-in
                    </span>
                  </router-link>
                  <button class="btn btn-secondary btn--request">
                    <span v-t="'account.reset-password.submit'">
                      Submit
                    </span>
                    <i class="icon icon-default icon-keyboard_arrow_right"></i>
                    <i class="icon spinner-border d-none mr-1" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      token: null,
      form: {
        email: this.$route.query.email,
        new_password: "",
        errors: []
      }
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        new_password: { required }
      }
    };
  },
  created() {},
  methods: {
    ...mapActions(["passwordReset"]),
    sendNewPassword(e) {
      let requestBtn = document.querySelector(".btn--request");
      this.addLoadingIcon(requestBtn);
      this.form.errors = [];
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.passwordReset({
          email: this.$route.query.email,
          token: this.$route.query.token,
          new_password: this.form.new_password
        }).then(response => {
          // this.$router.replace({ name: "Account" });
          // this.requestSend = true;
          if (response.data.resetPassword == null) {
            if (response.errors.length > 0) {
              let that = this;
              response.errors.forEach(function (element) {
                that.form.errors.push(element.message);
              });
            }
            this.removeLoadingIcon(requestBtn);
          } else {
            this.$router.replace({
              name: "Login",
              params: { resetpassword: true }
            });
            this.removeLoadingIcon(requestBtn);
          }
        });
      }
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
