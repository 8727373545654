<template>
  <section class="section shop">
    <div class="section__header text-center">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <h1 class="section__title h3" v-t="'account.forgot-password.title'">
              Forgot your password?
            </h1>
            <p
              class="section__intro text-sm"
              v-t="'account.forgot-password.description'"
            >
              Please enter your email address below. You will receive a link to
              reset your password.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section__body">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <div
              class="box__content bg-light p-3 mb-3"
              v-if="form.errors.length"
            >
              <div v-for="(error, i) in form.errors" :key="i">
                {{ error }}
              </div>
            </div>

            <form
              @submit.prevent="submit"
              @submit="requestPasswordReset($event)"
              v-if="!requestSend"
            >
              <div class="form-group mb-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <label
                    for="input-email-1"
                    class="label--big"
                    v-t="'account.login.email-label'"
                    >Email address</label
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.email.$error }"
                  id="input-email"
                  name="email"
                  aria-describedby="email-1"
                  :placeholder="$t('account.login.email-placeholder')"
                  v-model="form.email"
                />
              </div>
              <div class="form-group mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <router-link
                    :to="{ name: 'Login' }"
                    class="btn btn-sm btn-empty"
                  >
                    <i class="icon icon-keyboard_arrow_left"></i>
                    <span v-t="'account.forgot-password.back-to-sign-in'">
                      Back to sign-in
                    </span>
                  </router-link>
                  <button class="btn btn-secondary btn--request">
                    <span v-t="'account.forgot-password.submit'"> Submit </span>
                    <i class="icon icon-default icon-keyboard_arrow_right"></i>
                    <i class="icon spinner-border d-none mr-1" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </i>
                  </button>
                </div>
              </div>
            </form>

            <div class="box__content bg-light p-3 mb-3" v-else>
              <p v-t="'account.forgot-password.request-granted'">
                A request to reset your password has been sent. Please click the
                "Set a new password" button in your email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      form: {
        email: this.$route.query.email,
        errors: []
      },
      requestSend: false
    };
  },
  validations() {
    return {
      form: {
        email: { required, email }
      }
    };
  },
  methods: {
    ...mapActions(["sendPasswordReset"]),
    requestPasswordReset(e) {
      let requestBtn = document.querySelector(".btn--request");
      this.addLoadingIcon(requestBtn);
      this.form.errors = [];
      this.v$.$validate();
      if (!this.v$.$error) {
        this.sendPasswordReset(this.form.email)
          .then((response) => {
            if (response.data.requestPasswordResetEmail === true) {
              this.requestSend = true;
            } else {
              this.form.errors.push(this.$t("account.forgot-password.error"));
            }
            this.removeLoadingIcon(requestBtn);
          })
          .catch(() => {
            this.form.errors.push(this.$t("account.forgot-password.error"));
            this.removeLoadingIcon(requestBtn);
          });
      } else {
        this.removeLoadingIcon(requestBtn);
      }
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
